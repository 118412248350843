<template>
  <Swiper
    :modules="modules"
    slides-per-view="auto"
    :spaceBetween="12"
    :grabCursor="true"
    :slides-offset-before="16"
    :speed="600"
    class="related-articles-swiper"
    :class="$style.swiper"
  >
    <SwiperSlide
      v-for="(article, index) in relatedArticles"
      :key="index"
      class="max-w-[286px] sm:max-w-xs md:max-w-md"
    >
      <NuxtLink :to="localePathByType('ArticleRecord', article.slug as string)" noPrefetch>
        <div
          v-if="article.category.length"
          class="mb-3 flex w-fit rounded-full bg-white px-3 py-2 text-xs"
        >
          {{ article.category[0]?.name }}
        </div>

        <div class="mb-6 text-2xl text-blue-800 line-clamp-3">
          {{ article.title }}
        </div>
        <div class="relative">
          <div class="absolute bottom-5 right-5 z-2 h-16 w-16">
            <CircleButton class="!bg-none" icon-name="arrow-right" color="orange" />
          </div>
          <Image
            class="aspect-4/3 rounded-3xl object-cover"
            loading="lazy"
            :src="
              article.image?.responsiveImage
                ? article.image?.responsiveImage?.src
                : article.image?.url
            "
            :srcset="
              article.image?.responsiveImage
                ? article.image?.responsiveImage?.webpSrcSet
                : undefined
            "
            :width="
              article.image?.responsiveImage
                ? article.image?.responsiveImage?.width
                : article.image?.width
            "
            :height="
              article.image?.responsiveImage
                ? article.image?.responsiveImage?.height
                : article.image?.height
            "
            :sizes="article.image?.responsiveImage?.sizes"
            :alt="article.image?.alt!"
          />
        </div>
      </NuxtLink>
    </SwiperSlide>
  </Swiper>
</template>

<script setup lang="ts">
import { Parallax, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css'

defineProps({
  relatedArticles: {
    type: Object,
    default: () => {}
  }
})

const modules = [Parallax, A11y]
const { localePathByType } = useLocalePathByType()
</script>

<style lang="postcss" module>
.swiper {
  @apply relative mx-auto h-auto w-full pb-10;
}
</style>

<style lang="postcss">
.related-articles-swiper .swiper-wrapper {
  align-items: end !important;
}
</style>
